import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { isEqual } from 'lodash';
import { ApiTenderService } from '../../services/api/api-tender.service';
import {
  isGenerationHourBygone,
  MARKET_WATCHES_LIMIT,
  MarketWatch, MarketWatchDayOfWeek,
  MW_NAME_MAX_LENGTH,
  MW_NAME_MIN_LENGTH, MW_NEW_NAME_DEFAULT_VALUE,
  MW_DEFAULT_FREQUENCY
} from '../../models/market-watch';
import { TenderEntityService } from '../../services/tender-entity.service';
import { FilterType, TendersFilterComponent } from '../tenders-filter/tenders-filter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteWatchComponent } from '../modal-delete-watch/modal-delete-watch.component';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';
import { SortFieldEnum } from '../../models/tender-search-body';
import { MarketWatchManager, TendersModuleService } from '../../services/tenders-module.service';
import { isSameDay, findPeriod, toDayPeriod, findTheoricClosestWatchDate } from "../../../shared/helpers/date-helper";
import { ModalManageWatchUsersComponent } from "../modal-manage-watch-users/modal-manage-watch-users.component";
import {
  WatchUser
} from "../../../common-explain/components/ex-watch-users-selector/ex-watch-users-selector.component";
import { ModalUnsubcribeWatchComponent } from "../modal-unsubcribe-watch/modal-unsubcribe-watch.component";
import { ModalManageWatchFrequencyComponent } from "../modal-manage-watch-frequency/modal-manage-watch-frequency.component";

const days = require('../../../shared/helpers/days.json') as MarketWatchDayOfWeek[];

@Component({
  selector: 'app-tenders-watch',
  templateUrl: './tenders-watch.component.html',
  styleUrls: ['./tenders-watch.component.scss']
})
export class TendersWatchComponent {
  protected readonly MARKET_WATCHES_LIMIT = MARKET_WATCHES_LIMIT;
  protected readonly MARKET_WATCHES_NAME_LIMIT = MW_NAME_MAX_LENGTH;
  protected readonly MW_NEW_NAME_DEFAULT_VALUE = MW_NEW_NAME_DEFAULT_VALUE;
  protected readonly FilterType = FilterType;
  protected readonly SortFieldEnum = SortFieldEnum;

  @ViewChild(TendersFilterComponent) filterComponent!: TendersFilterComponent;
  @ViewChild('nameInput') nameInput!: ElementRef<HTMLElement>;
  @ViewChild('marketWatchActionToast', {read: TemplateRef}) marketWatchActionToast!: TemplateRef<any>;

  newName?: string;
  newMarketWatchName?: string;
  currentUserId!: number;
  note = '';

  constructor(
    private apiTenderService: ApiTenderService,
    protected tenderEntityService: TenderEntityService,
    protected tendersModuleService: TendersModuleService,
    private modalService: NgbModal,
    private toastMessageStackService: ToastMessageStackService,
  ) {
    if (!this.marketWatchManagers.length) this.tendersModuleService.newMarketWatch();
    // Update tenderTopics in case of changes
    this.tendersModuleService.initTenderTopics();
    this.currentUserId = +(localStorage.getItem('user_id') ?? 0);
  }

  get marketWatchManagers() {
    return this.tendersModuleService.marketWatchManagers;
  }

  get currentManager() {
    return this.tendersModuleService.currentManager;
  }

  newMarketWatch() {
    this.tendersModuleService.newMarketWatch();
  }

  async saveMarketWatch() {
    const marketWatchId = this.currentManager.marketWatch?.id;
    const storedNewMarketWatchName = this.newMarketWatchName || this.currentManager.newMarketWatchName;
    this.clearNewMarketWatchName();
    if (!this.currentManager.service.watchFilters) {
      this.showToast('generic', 'error');
      return;
    }
    if (marketWatchId) {
      if (this.hasChanges())
        this.apiTenderService.marketWatch.updateMarketWatch(marketWatchId, this.currentManager.service.watchFilters)
          .then(() => {
            if (this.currentManager.marketWatch && this.currentManager.service.watchFilters) {
              this.currentManager.marketWatch.watchFilters = this.currentManager.service.watchFilters;
              this.currentManager.marketWatch.updatedAt = new Date().toISOString() ?? '';
            }
            this.currentManager.service.isObsolete = false;
            this.showToast('edit', 'success', this.currentManager.marketWatch?.name);
          })
          .catch(() => {
            this.showToast('edit', 'error');
          });
      this.toggleEditMode(false);
    } else {
      const watchFilters = this.currentManager.service.watchFilters;
      // gestion erreur de nom de nouvelle veille
      if (!watchFilters || !storedNewMarketWatchName) {
        this.showToast('generic', 'error');
        return;
      } else {
        const res = await this.apiTenderService.marketWatch.createMarketWatch({
          ...watchFilters,
          name: storedNewMarketWatchName,
          owner_user_id: this.currentUserId,
          day_of_week: MW_DEFAULT_FREQUENCY,
        });
        if (res) {
          this.currentManager.marketWatch = new MarketWatch({
            id: res.market_watch_id,
            owner_user_id: this.currentUserId,
            name: storedNewMarketWatchName,
            day_of_week: MW_DEFAULT_FREQUENCY,
            created_at: new Date().toISOString(),
            ...watchFilters,
          });
          this.currentManager.service.marketWatchId = res.market_watch_id;
          this.toggleEditMode(false);
          this.showToast('save', 'success', storedNewMarketWatchName);
          // Sync filters with the service
          this.filterComponent.updateFilters();
          // After the creation of a watch, restore the current date to today.
          this.currentManager.service.selectedPeriod = toDayPeriod(new Date());
        }
      }
    }
    this.currentManager.creationMode = false;
  }

  newParamsNotActiveYet() {
    return isSameDay(new Date(this.currentManager.marketWatch?.updatedAt ?? ''), new Date()) &&
      isGenerationHourBygone();
  }

  clearNewMarketWatchName() {
    this.currentManager.newMarketWatchName = undefined;
    this.newMarketWatchName = undefined;
  }

  async renameMarketWatch() {
    const name = this.nameInput.nativeElement.innerText.trim();
    if (!this.currentManager.marketWatch || this.isInvalidName() || (this.currentManager.marketWatch.name === name)) return;
    const marketWatchId = this.currentManager.marketWatch.id;
    const res = await this.apiTenderService.marketWatch.updateMarketWatch(
      marketWatchId,
      { name }
    )
      .catch(() => {
        this.showToast('rename', 'error');
      });
    if (res) {
      this.currentManager.marketWatch.name = name;
      this.currentManager.marketWatch.updatedAt = new Date().toISOString();
      this.showToast('rename', 'success', name);
    }
    this.nameInput.nativeElement.blur();
    this.resetName();
  }

  deleteMarketWatch() {
    const marketWatchId = this.currentManager.marketWatch?.id;
    if (!marketWatchId) { this.showToast('delete', 'error'); return; }
    const modalRef = this.modalService.open(ModalDeleteWatchComponent, { centered: true });
    modalRef.componentInstance.watchName = this.currentManager.marketWatch?.name;
    modalRef.componentInstance.watchUsersLength = this.currentManager.marketWatch?.watchUsers?.filter(user => user.is_market_watch_user).length ?? 0;
    modalRef.result.then(() => {
      this.apiTenderService.marketWatch.deleteMarketWatch(marketWatchId)
        .then(() => {
          this.showToast('delete', 'success', this.currentManager.marketWatch?.name);
          this.removeCurrentManager();
        })
        .catch(() => {
          this.showToast('delete', 'error');
        });
    }).catch(()=>null);
  }

  toggleEditMode(newValue?: boolean) {
    if (newValue === undefined) this.currentManager.editMode = !this.currentManager.editMode;
    else this.currentManager.editMode = newValue;
    this.currentManager.service._viewMode$.next(this.currentManager.editMode ? 'preview' : 'watch');
  }

  async openFrequencyModal() {
    const marketWatchId = this.currentManager.marketWatch?.id;
    if (!marketWatchId) {
      this.showToast('unsubscribe', 'error', this.currentManager.marketWatch?.name);
      return;
    }
    const modalRef = this.modalService.open(ModalManageWatchFrequencyComponent, { centered: true, windowClass: 'modal-width-medium' });
    modalRef.componentInstance.daysOfWeek = this.currentManager.marketWatch?.dayOfWeek
    modalRef.result.then(async (selectedDays) => {
      await this.apiTenderService.marketWatch.updateMarketWatch(
        this.currentManager.marketWatch?.id ?? 0,
        {day_of_week: selectedDays.join(',')}
      ).then(() => {
        if (!this.currentManager.marketWatch) return;
        this.currentManager.marketWatch.dayOfWeek = selectedDays;
        this.currentManager.marketWatch.updatedAt = new Date().toISOString();
        this.showToast('edit', 'success', this.currentManager.marketWatch?.name);
      })
        .catch(() => {
          this.showToast('edit', 'error', this.currentManager.marketWatch?.name);
        });
    });
  }


  hasChanges() {
    return !isEqual(this.currentManager.marketWatch?.watchFilters, this.currentManager.service.watchFilters);
  }

  hasNewMarketWatch() {
    return this.tendersModuleService.hasNewMarketWatch();
  }

  revertChanges() {
    if (!this.currentManager.marketWatch) return;
    if (this.hasChanges()) this.currentManager.service.watchFilters = {
      ...this.currentManager.marketWatch.watchFilters, market_watch_id: this.currentManager.marketWatch.id, propagate: false
    };
    this.currentManager.creationMode = false;
    this.filterComponent.updateFilters();
    this.toggleEditMode(false);
  }

  getDate() {
    if (!this.currentManager.service.selectedPeriod) return new Date().toISOString().split('T')[0];
    return this.currentManager.service.selectedPeriod.to;
  }

  onDaySelect(date: Date) {
    // If we are in editMode, return the basic 1-day period, else return the period between two watch dates.
    this.currentManager.service.selectedPeriod = findPeriod(date, this.currentManager.marketWatch?.dayOfWeek, !this.currentManager.editMode ? this.currentManager.marketWatch?.marketWatchDates : undefined);
  }

  resetName() {
    if (this.newName === undefined) return;
    this.newName = undefined;
    this.nameInput.nativeElement.innerText = this.currentManager.marketWatch?.name ?? '';
  }

  resetNewMarketWatchName() {
    if (this.newMarketWatchName === undefined) return;
    this.currentManager.newMarketWatchName = this.newMarketWatchName.trim();
    this.newMarketWatchName = undefined;
    this.nameInput.nativeElement.innerText = this.currentManager?.newMarketWatchName ?? this.MW_NEW_NAME_DEFAULT_VALUE;
  }

  isInvalidName(evaluatedName: string | undefined = this.newName) {
    return (
      !evaluatedName ||
      evaluatedName.length < MW_NAME_MIN_LENGTH ||
      evaluatedName.length > MW_NAME_MAX_LENGTH ||
      this.isExistingName(evaluatedName)
    )
  }

  isExistingName(name?: string) {
    return !!name && this.marketWatchManagers
      .filter(manager => manager !== this.currentManager)
      .some(manager => manager.marketWatch?.name.trim().toLowerCase() === name?.trim().toLowerCase());
  }

  closeNewMarketWatch(manager: MarketWatchManager) {
    this.tendersModuleService.removeManager(manager);
  }

  /**
   * méthode pour afficher les toasts en fonction du type d'action et du message
   */
  showToast(action: 'edit' | 'save' | 'rename' | 'delete' | 'move' | 'generic' | 'unsubscribe', message: 'success' | 'error', entityName?: string) {
    let options: any = {autohide: true, classname: message+'-toast-tenders' + ' toast-shape', action: action};
    if (entityName) options = {...options, name: entityName};
    this.toastMessageStackService.show(this.marketWatchActionToast, options);
  }

  async manageWatchUsers() {
    const modalRef = this.modalService.open(ModalManageWatchUsersComponent, { centered: true, windowClass: 'user-watch-modal' });
    modalRef.componentInstance.marketWatchUsers = await this.tendersModuleService.getCurrentMarketWatchUsers();
    modalRef.result.then(async (res) => {
      const selectedUsers = res as WatchUser[];
      await this.apiTenderService.marketWatch.updateMarketWatch(
        this.currentManager.marketWatch?.id ?? 0,
        {users: selectedUsers.filter(i => i.is_market_watch_user).map((x) => x.id).concat([this.currentUserId])}
      ).then(() => {
        if (!this.currentManager.marketWatch) return;
        const newUsers = selectedUsers.filter(user => user.is_market_watch_user);
        this.currentManager.marketWatch.watchUsers = newUsers.map((user) => {
          delete user.is_market_watch_user
          return user;
        });
        this.currentManager.marketWatch.updatedAt = new Date().toISOString();
        this.showToast('edit', 'success', this.currentManager.marketWatch?.name);
      })
        .catch(() => {
          this.showToast('edit', 'error', this.currentManager.marketWatch?.name);
        });
    });
  }

  async unsubscribe() {
    const marketWatchId = this.currentManager.marketWatch?.id;
    if (!marketWatchId) { this.showToast('unsubscribe', 'error', this.currentManager.marketWatch?.name); return; }
    const modalRef = this.modalService.open(ModalUnsubcribeWatchComponent, { centered: true });
    modalRef.componentInstance.ownerUser = await this.apiTenderService.marketWatch.retrieveOwnerUserIdentity(this.currentManager.marketWatch?.ownerUserId ?? 0);
    modalRef.result.then(() => {
      this.apiTenderService.marketWatch.deleteUserMarketWatch(
        marketWatchId,
      ).then(() => {
        this.showToast('unsubscribe', 'success', this.currentManager.marketWatch?.name);
        this.removeCurrentManager();
      })
        .catch(() => {
          this.showToast('unsubscribe', 'error', this.currentManager.marketWatch?.name);
        });
    }).catch(()=>null);
  }

  removeCurrentManager() {
    this.tendersModuleService.removeCurrentManager();
  }

  get watchUsersTooltip(): {sub_count_minus_5: number, pre_text_key: string, text: string, post_text_key: string} {
    const subscribedUsers = this.currentManager.marketWatch?.watchUsers ?? [];
    const firstFiveNames = subscribedUsers.slice(0, 5)
      .map(user => user.name).join(', ') ?? '';
    const subscribedUsersCountMinus5 = subscribedUsers.length - 5;
    const preTextKey = this.currentManager.marketWatch?.isOwnedByCurrentUser ? 'pre-text-owner' : 'pre-text-not-owner';
    const postTextKey = (subscribedUsers.length - 5) > 1 ? 'post-text-n' : (subscribedUsers.length - 5) === 1 ? 'post-text-1' : 'none';
    return {
      sub_count_minus_5: subscribedUsersCountMinus5,
      pre_text_key: preTextKey,
      text: firstFiveNames,
      post_text_key: postTextKey
    };
  }

  findNextWatchDay() {
    const date = findTheoricClosestWatchDate(new Date(), this.tendersModuleService.currentManager.marketWatch?.dayOfWeek, true)
    return days.find(d => d.index === date.getDay())?.value
  }
}
