import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  FindResultMatchesCount,
  FindState,
  NgxExtendedPdfViewerService,
  PagesLoadedEvent
} from 'ngx-extended-pdf-viewer';
import {
  aoDocumentImportanceMapping,
  DceDocument,
  DceDocumentIconName,
  TenderAvisType
} from '../../models/dce-document';
import { getDceDocumentDisplayedName, TenderDetail, TreeNode } from '../../models/tender-detail';
import { ChunkBoxManagerService } from '../../services/helpers/chunk-box-manager.service';
import { ExportService } from "../../../shared/services/export.service";
import { ToastMessageStackService } from "../../../shared/services/toast-message-stack.service";
import { ApiTenderService } from "../../services/api/api-tender.service";
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { ChunkDetail } from '../../models/chunk-detail';

@Component({
  selector: 'app-tender-pdfs-reader',
  templateUrl: './tender-pdfs-reader.component.html',
  styleUrls: ['./tender-pdfs-reader.component.scss']
})
export class TenderPdfsReaderComponent implements OnChanges, AfterViewInit {
  protected readonly TenderAvisType = TenderAvisType;
  protected readonly Object = Object;
  protected readonly DceDocumentIconName = DceDocumentIconName;
  @Input({required: true}) tenderDetail!: TenderDetail;
  @Output() documentView = new EventEmitter<{ url: string, name: string }>();
  @Input() clickedRef!: ChunkDetail | null;
  @Output() clickedRefChange = new EventEmitter<ChunkDetail | null>();

  document!: DceDocument;
  nbPages = 1;
  dropdownPlaceholder: string | undefined;
  showLoader = false;
  withPageChangeTracked = true;
  private _page = 1;
  dropdownMode: 'condensed' | 'expanded' = 'condensed';
  forbiddenPublicationTypes = [TenderAvisType.AO_AVIS_SOURCE];
  forbiddenItemTypes = ['unknown-file', 'no-preview-file'];

  downloadUrl!: string;
  blob: Blob | null = null;
  downloading = false;
  downloadingTimeout: any = null;
  timeLimit = 180000; // 180 secondes
  @ViewChild('exportInProgressToast', {read: TemplateRef}) exportInProgressToast!: TemplateRef<any>;
  @ViewChild('errorInExport', {read: TemplateRef}) errorInExport!: TemplateRef<any>;
  className = 'toast-export-in-progress';

  constructor(private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
              private apiTenderService: ApiTenderService,
              private exportService: ExportService,
              private toastMessageStackService: ToastMessageStackService,
              private userTrackerService: UserTrackerService,
              private chunkBoxManagerService: ChunkBoxManagerService) {
  }

  ngOnChanges(changes: any) {
    if (changes.tenderDetail && this.tenderDetail.dceDocuments) {
      this.setMostInterestingDocument();
      this.updateLoaderState(true, this.document);
      this.dropdownPlaceholder = getDceDocumentDisplayedName(this.document);
    }
    if (changes.tenderDetail && this.tenderDetail.dceTree) {
      this.sortTreeNode(this.tenderDetail.dceTree);
      this.setItemTypeToTree(this.tenderDetail.dceTree);
      this.setItemTypeToTree(this.tenderDetail.condensedDceTree);
      this.dropdownMode = (this.tenderDetail.strictSmartDocumentNumber > 0) ? 'condensed' : 'expanded';
    }
    if (changes.clickedRef) {
      this.withPageChangeTracked = false;
      this.chunkBoxManagerService.removeBoundingBox(); // Remove the previous bounding box
      if (this.clickedRef) { // A question or ref has been selected
        this.moveToCorrectDocument();
      } else {
        this.withPageChangeTracked = true;
      }
    }
  }

  setMostInterestingDocument() {
    if (this.tenderDetail && this.tenderDetail.dceDocuments) {
      this.document = this.tenderDetail?.dceDocuments.map(_ => _)
          .sort((a, b) => {
            const default_value = aoDocumentImportanceMapping['default_value'];
            return (aoDocumentImportanceMapping[a.publicationType] ?? default_value) - (aoDocumentImportanceMapping[b.publicationType] ?? default_value);
          }).at(0)
        ?? this.tenderDetail?.dceDocuments[0];
    }
  }

  get page(): any {
    return this._page;
  }

  set page(value: any) {
    this._page = value;
    if (value && this.withPageChangeTracked) {
      this.trackEvent({
          page: parseInt(value),
          document: this.document,
        },
        EventTypeName.TENDER_DCE_DOCUMENT_PAGE_SCROLLED)
    }
    this.withPageChangeTracked = true;
  }

  moveToCorrectDocument() {
    const docUid = this.clickedRef?.docUid;
    const documentWithAnswer = this.tenderDetail.dceDocuments.find((dceDocument: DceDocument) => dceDocument.uid === docUid)!;
    if (documentWithAnswer.uid !== this.document.uid) { // The chunk found is on another document
      this.updatePdfReaderAttributes(documentWithAnswer);
    } else { // The chunk found is on the current document
      this.manageDrawingBoundingBox(true);
    }
  }

  onPagesLoaded(value: PagesLoadedEvent): void {
    this.nbPages = value.pagesCount;
    this.updateLoaderState(false);
    this.manageDrawingBoundingBox(true);
  }

  manageDrawingBoundingBox(withScroll = false) {
    if (this.clickedRef && !document.getElementById('chunk-box')) {
      this._searchtext = ''; // we reset the ctrl+f search
      const chunkPagesBboxes = this.clickedRef.chunkPagesBboxes;
      this.chunkBoxManagerService.chooseChunkPosition(chunkPagesBboxes, withScroll);
    }
  }

  unselectChunk() { // Chunk unselected when manually changing document or launching a ctrl+f search
    this.clickedRef = null;
    this.clickedRefChange.emit(this.clickedRef);
    this.chunkBoxManagerService.removeBoundingBox();
  }

  sortTreeNode(treeNode: TreeNode) {
    // 1. On met les documents d'un côté et les dossiers de l'autre.
    // 2. On trie les documents par ordre alphabétique, idem pour les dossiers.
    // 3. On applique la même méthode de tri d'arbre aux dossiers eux-mêmes.
    // 4. On concatène les deux tableaux.
    const documents = treeNode.children?.filter(child => !child.children).sort((a, b) => {
      // attention, on ne trie pas les avis qui sont déjà bien ordonnés
      if (Object.values(TenderAvisType).includes(a.value.publicationType) || Object.values(TenderAvisType).includes(b.value.publicationType)) {
        return 0;
      }
      return (a.value.name.localeCompare(b.value.name));
    }) ?? [];
    const folders = treeNode.children?.filter(child => child.children).sort((a, b) => a.value.name.localeCompare(b.value.name)) ?? [];
    folders.forEach(folder => this.sortTreeNode(folder));
    treeNode.children = [...documents, ...folders];
  }

  /** Récursive set of itemType for each component */
  setItemTypeToTree(tree: TreeNode) {
    this.setItemTypeToTreeNode(tree);
    tree.children?.forEach((child: TreeNode) => {
      this.setItemTypeToTreeNode(child);
      child.children?.forEach((child: TreeNode) => {
        this.setItemTypeToTree(child);
      });
    });
  }

  setItemTypeToTreeNode(item: TreeNode) {
    let itemType: string;
    if (item.children) {
      itemType = 'directory';
    } else {
      if (item.value?.iconName === 'file-unknown') { // invalid type
        itemType = 'unknown-file'
      } else {
        itemType = 'known-file'
      }
      if (item.value?.previewUrl === null) { // no preview stronger than known/unknown file
        itemType = 'no-preview-file';
      }
    }
    item.value = {...item.value, itemType: itemType};
  }


  //region ** partie recherche **
  highlightAll = false;
  matchCase = false;
  wholeWord = false;
  ignoreAccents = false;
  findState!: FindState;
  totalMatches = 0;
  currentMatchNumber = 0;
  _searchtext = '';
  _textChanged = false;
  searchNotFound = false;

  public get searchtext(): string {
    return this._searchtext;
  }

  public set searchtext(text: string) {
    const search = this.ngxExtendedPdfViewerService.find(text, {
      highlightAll: this.highlightAll,
      matchCase: this.matchCase,
      wholeWords: this.wholeWord,
      ignoreAccents: this.ignoreAccents
    });
    if (search) {
      this.searchNotFound = false;
      this._textChanged = text !== this._searchtext;
      this._searchtext = text;
      if (!this._searchtext) {
        this.find();
        this.totalMatches = 0;
        this.currentMatchNumber = 0;
      } else {
        this.trackEvent({search_text: text, document: this.document},
          EventTypeName.TENDER_DCE_PDF_SEARCH);
        this.unselectChunk();
      }
    }
  }

  public findNext(): void {
    this.ngxExtendedPdfViewerService.findNext();
  }

  public findPrevious(): void {
    this.ngxExtendedPdfViewerService.findPrevious();
  }

  public updateFindState(result: FindState) {
    this.findState = result;
  }

  public updateFindMatchesCount(result: FindResultMatchesCount) {
    this.currentMatchNumber = result.current ?? 0;
    this.totalMatches = result.total ?? 0;
    this.searchNotFound = !!this._searchtext.length && !this.totalMatches;
    if (this.searchNotFound) {
      setTimeout(() => {this.searchNotFound = false;}, 3000);
    }
  }

  find(): void {
    if (this._textChanged) {
      this._textChanged = false;
      this.currentMatchNumber = 1;
    } else {
      this.findNext();
    }
  }

  //endregion

  setVisibilityRecursive(item: TreeNode, visibility: boolean) {
    item.children?.forEach((child: TreeNode) => {
      child.value = {...child.value, visible: visibility}
      this.setVisibilityRecursive(child, visibility ? child.value.expanded : visibility);
    });
  }

  itemSelected(item: TreeNode, event: any) {
    this.withPageChangeTracked = false;
    event.stopPropagation();
    // gestion du déploiement dans le cas d'un dossier
    if (item.children) {
      this.setVisibilityRecursive(item, !item.value.expanded);
      item.value.expanded = !item.value.expanded;
    }
    // STOP dans les trois cas suivants :
    if (item.children ||                                      // un dossier
      (this.document.uid === item.value.uid)                 // un document déjà chargé
    ) return;
    // métier
    this.trackEvent({former_document: this.document, document: item.value},
      EventTypeName.TENDER_DCE_DOCUMENT_CHANGED);
    this.unselectChunk(); // Unselect the chunk when manually changing document
    this.page = 1;
    this.updatePdfReaderAttributes(item.value);
  }

  updatePdfReaderAttributes(newDocument: DceDocument) {
    this.page = 1;
    this.updateLoaderState(true, newDocument);
    this.searchtext = '';
    this.document = newDocument;
    this.dropdownPlaceholder = getDceDocumentDisplayedName(this.document);
    this.documentView.emit({url: this.document.previewUrl, name: this.document.name});
  }

  /** met a jour le loader, en vérifiant que le document courant n'est pas d'un format interdit */
  updateLoaderState(boolean: boolean, targetDocument?: DceDocument) {
    if (boolean && targetDocument) {
      this.showLoader = !this.forbiddenPublicationTypes.includes(targetDocument.publicationType as TenderAvisType);
    } else {
      this.showLoader = false;
    }
  }

  pdfLoadingFailed(event: any) {
    console.log('pdfLoadingFailed FIRED');
    this.updateLoaderState(false);
    console.log('pdfLoadingFailed :', event);
  }

  dropdownToggle(event: any) {
    // si le loader est toujours affiché (doc long à charger par ex) alors on
    // masque le loader à l'ouverture du dropdown.
    if (event && this.showLoader) {
      this.updateLoaderState(false);
    }
  }

  get numberOfAvisToDisplay(): number {
    return this.tenderDetail.dceTree.children?.filter(child => Object.values(TenderAvisType).includes(child.value.publicationType)).length ?? 0;
  }

  documentHasToBeHidden(document: DceDocument) {
    return (this.forbiddenPublicationTypes.includes(document?.publicationType as TenderAvisType)) ||
      (this.tenderDetail.dceDocuments.length === 0);
  }

  //region * gestion du téléchargement du DCE *
  async onDownloadDce() {
    this.downloading = true;
    this.showExportToast();
    this.downloadUrl = await firstValueFrom(
      this.apiTenderService.dce.getDceDownloadUrl(
        this.tenderDetail.id,
        this.tenderDetail.dceProjectUid,
        this.tenderDetail.inquirers,
        60000
      )
    ).catch((err) => {
      // STOP 1
      this.manageApiError('récupération de l\'url de téléchargement', err);
      throw new Error('récupération de l\'url de téléchargement ratée');
    });
    // Arret des tentatives d'export après timeLimit ms
    this.downloadingTimeout = setTimeout(() => this.manageApiError(
      `retrieving blob and saving it`,
      'timeout dépassé'), this.timeLimit);
    await this.getZipBlobAndSaveIt();
  }

  /** méthode qui cherche à récupérer le zip toutes les secondes tant que
   * this.downloading est vrai
   * Quand le blob est trouvé, la méthode se charge de l'enregistrement */
  async getZipBlobAndSaveIt() {
    if (!this.downloading) return;
    setTimeout(async () => {
      await firstValueFrom(
        this.apiTenderService.dce.getBlobFromUrl(this.downloadUrl)
      )
        .then((blob) => {
          const fileName = decodeURIComponent(this.exportService.getFileNameFromURL(this.downloadUrl) ?? 'DCE.zip');
          this.exportService.saveZipToComputer(
            new Blob([blob], {type: 'application/zip'}),
            fileName
          )
          this.stopExportAndRemoveExportToast();
        })
        .catch((err) => {
          console.log(err);
          if (err === 'Not Found') {
            this.getZipBlobAndSaveIt();
            return null;
          } else {
            this.manageApiError('récupération du zip', err);
            throw new Error('récupération du zip raté');
          }
        });
    }, 1000);
  }

  //endregion

  //region gestion des toasts d'export

  ngAfterViewInit(): void {
    // Setup className used for toasts. t-e-i-p is short for toast-export-in-progress
    this.className = `toast-export-in-progress t-e-i-p-${this.tenderDetail.id}`;
  }

  /** Removing related toast and killing export procedure */
  stopExportAndRemoveExportToast() {
    this.downloading = false;
    clearTimeout(this.downloadingTimeout);
    this.removeExportToast();
  }

  /** Remove export toast and display error toast */
  manageApiError(stepName: string, e = 'error message not provided') {
    // arret de l'export
    this.stopExportAndRemoveExportToast()
    // affichage du toast d'erreur
    this.displayErrorToast();
  }

  /** Display export toast and adapt export button style */
  showExportToast() {
    this.toastMessageStackService.show(this.exportInProgressToast, {classname: this.className + ' toast-shape'});
    this.downloading = true;
  }

  /** Remove export toast and adapt export button style */
  removeExportToast() {
    // Adapting style
    this.downloading = false;
    // Manage large export in progress toast
    this.toastMessageStackService.removeByClassName(`t-e-i-p-${this.tenderDetail.id}`);
    // Manage light toast (hidden export in progress)
    this.toastMessageStackService.removeByClassName(`l-t-${this.tenderDetail.id}`);
  }

  displayErrorToast() {
    this.toastMessageStackService.show(this.errorInExport, {autohide: true, classname: 'error-toast toast-shape'});
  }

  //endregion

  //region ** partie tracking **

  trackUrlClick() {
    firstValueFrom(this.userTrackerService.track({
      event_type: EventTypeName.TENDER_URL_CLICK,
      event_timestamp: (new Date()).toISOString(),
      ...this.userTrackerService.buildBasicUserInformations(),
      tender_id: this.tenderDetail.id,
      url: this.tenderDetail.dceUrl,
    }))
  }

  //endregion

  dceSectionText(): string {
    if (!this.tenderDetail.dceProjectUid) {
      return 'tenders.no-dce';
    } else if (!this.tenderDetail.strictSmartDocumentNumber) {
      return 'tenders.tree-header.dce';
    } else if (this.dropdownMode === 'expanded') {
      return 'tenders.tree-header.full-dce';
    } else if (this.dropdownMode === 'condensed') {
      return 'tenders.tree-header.dce'
    } else return 'tenders.tree-header.dce';
  }


  dropdownModeSwitchClick(event: MouseEvent) {
    event?.stopPropagation();
    this.dropdownMode = this.dropdownMode === 'condensed' ? 'expanded' : 'condensed';
  }

  trackEvent(data = {}, eventName = '') {
    firstValueFrom(this.userTrackerService.track({
      event_type: eventName,
      event_timestamp: (new Date()).toISOString(),
      ...this.userTrackerService.buildBasicUserInformations(),
      tender_uid: this.tenderDetail.dceDocuments[0].rawKey.split('/')[3],
      ...data
    }));
  }
}
